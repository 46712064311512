<template>
  <div>
    <span class="title grey--text text--darken-1">{{
        $_t('Fill in your contact data')
      }}</span>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" class="pt-5 pb-8">
            <span class="subtitle-1 grey--text text--darken-2">
              {{ $_t('Personal details') }}</span>
            <div class="divider-line"></div>
          </v-col>
        </v-row>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            :disabled="submitted"
        >
          <v-text-field
              v-model="name"
              :rules="nameRules"
              :label="$_t('First name') + ' *'"
              outlined
              color="primary"
              class="mb-2"
              :disabled="disabledInputs.includes('name')"
          ></v-text-field>

          <v-text-field
              v-model="surname"
              :rules="surnameRules"
              :label="$_t('Last name') + ' *'"
              outlined
              color="primary"
              class="mb-2"
              :disabled="disabledInputs.includes('surname')"
          ></v-text-field>

          <v-text-field
              v-model="patientNumber"
              v-if="false"
              :rules="patientNumberRules"
              :label="$_t('NHS number')"
              outlined
              color="primary"
              class="mb-2"
              :disabled="disabledInputs.includes('patientNumber')"
              validate-on-blur
          ></v-text-field>

          <v-menu
              ref="menu"
              v-model="birthdayMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
              :disabled="disabledDoB"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="birthdayFormatted"
                  :label="$_t('Date of birth') + ' *'"
                  readonly
                  :rules="birthdayRules"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  :disabled="disabledInputs.includes('birthday')"
              ></v-text-field>
            </template>
            <v-date-picker
                ref="birthdayPicker"
                v-model="birthday"
                :max="new Date().toISOString().substr(0, 10)"
                min="1900-01-01"
                @change="setBirthday()"
                :locale="getLocale()"
                :first-day-of-week="1"
            ></v-date-picker>
          </v-menu>

          <v-select
              v-model="sex"
              :items="sexList"
              :label="$_t('Sex')"
              outlined
              color="primary"
              class="mb-2"
          ></v-select>

          <v-row>
            <v-col cols="12" class="pb-8">
              <span class="subtitle-1 grey--text text--darken-2">{{
                  $_t('Address')
                }}</span>
              <div class="divider-line"></div>
            </v-col>
          </v-row>

          <v-text-field
              v-model="streetName"
              :label="$_t('Address line 1')"
              :rules="[
              (v) =>
                !v ||
                (v && v.length <= 100) ||
                $_t('Address line 1') +
                  ' ' +
                  $_t('must be less than or equal to') +
                  ' 100 ' +
                  $_t('characters')
            ]"
              outlined
              color="primary"
              class="mb-2"
              :disabled="disabledInputs.includes('streetName')"
          ></v-text-field>

          <v-text-field
              v-model="streetNumber"
              :label="$_t('Address line 2')"
              :rules="[
              (v) =>
                !v ||
                (v && v.length <= 20) ||
                $_t('Street number') +
                  ' ' +
                  $_t('must be less than or equal to') +
                  ' 20 ' +
                  $_t('characters')
            ]"
              outlined
              color="primary"
              class="mb-2"
              :disabled="disabledInputs.includes('streetNumber')"
          ></v-text-field>

          <v-text-field
              v-model="town"
              :label="$_t('City')"
              :rules="[
              (v) =>
                !v ||
                (v && v.length <= 50) ||
                $_t('City') +
                  ' ' +
                  $_t('must be less than or equal to') +
                  ' 50 ' +
                  $_t('characters')
            ]"
              outlined
              color="primary"
              class="mb-2"
              :disabled="disabledInputs.includes('town')"
          ></v-text-field>

          <v-text-field
              v-model="zipCode"
              :label="$_t('Post code')"
              :rules="[
              (v) =>
                !v ||
                (v && v.length <= 7) ||
                $_t('Post code') +
                  ' ' +
                  $_t('must be less than or equal to') +
                  ' 7 ' +
                  $_t('characters')
            ]"
              outlined
              color="primary"
              class="mb-2"
              :disabled="disabledInputs.includes('zipCode')"
          ></v-text-field>

          <v-row>
            <v-col cols="12" class="pb-8">
              <span class="subtitle-1 grey--text text--darken-2">{{
                  $_t('Contact details')
                }}</span>
              <div class="divider-line"></div>
            </v-col>
          </v-row>

          <vue-tel-input
              v-model="phoneNumber"
              @validate="onValidateMobile"
              inputClasses="phone-margin-fix v-input__slot"
              style="color: #000;line-height:2.3em;"
              :inputOptions="{placeholder: $_t('Mobile number') + ' *'}"
              name="mobileNumber"
              v-bind:style="{
                'border-color': (mobileValid ? 'rgba(0,0,0,.42)' : '#ff5252'),
                'border-width': (mobileValid ? '1px' : '2px'),
              }"
              @blur="onBlurMobile"
              mode="international"
              :disabled="disabledInputs.includes('phoneNumber')"
              defaultCountry="GB"
          >
            <template v-slot:arrow-icon>
              <span>{{ '▼' }}</span>
            </template>
          </vue-tel-input>

          <v-text-field
              v-model="email"
              :label="
              agreementEmailCheckbox
                ? $_t('Email address') + ' *'
                : $_t('Email address (optional)')
            "
              :rules="emailRules"
              outlined
              color="primary"
              class="mt-10"
              :disabled="disabledInputs.includes('email')"
              validate-on-blur
          ></v-text-field>
          <v-row>
            <v-col v-if="isReferralLetterRequired" cols="12" class="my-0 py-0">
              <span  class="subtitle-1">{{ $_t('Referral is required') }}</span><br>
              <span v-if="isReferralLetterRequiredInAdvance" class="subtitle-2">{{ $_t('You must upload your referral letter to complete the booking') }}</span>
              <span v-else class="subtitle-2">{{ $_t('You may upload your referral letter now or bring it with you on the day of your appointment') }}</span>
            </v-col>
            <v-col v-if="!isReferralLetterRequired" cols="12" class="my-0 py-0">
              <span  class="subtitle-1">{{ $_t('Referral is not required') }}</span>
              <v-radio-group
                  v-model="referralType"
                  class="my-0"
              >
                <v-radio
                    label="I don't have a referral"
                    value="TEXT"
                ></v-radio>
                <v-radio
                    label="I have a referral"
                    value="UPLOAD"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row
            v-if="referralType === 'UPLOAD'">
            <v-col cols="12" class="mb-6">
              <div class="example-simple">
                <div class="upload">
                  <ul>
                    <li v-for="file in files" :key="file.id">
                      <span>{{file.name}}</span>&nbsp;&nbsp;&nbsp;
                      <span v-if="file.error">{{file.error}}</span>
                      <span v-else-if="file.success">uploaded successfully</span>
                      <span v-else-if="file.active">uploading</span>
                      <span v-else-if="!!file.error">{{file.error}}</span>
                      <span v-else></span>
                    </li>
                  </ul>
                  <div class="example-btn">
                    <div v-show="!files || !files.length">
                      <file-upload
                          class="btn btn-primary"
                          :post-action="uploadRoute"
                          extensions="gif,jpg,jpeg,png,webp,pdf"
                          accept="image/png,image/gif,image/jpeg,image/webp,application/pdf'"
                          :multiple="false"
                          :size="1024 * 1024 * 10"
                          v-model="files"
                          @input-filter="inputFilter"
                          ref="upload">
                        <v-btn>
                          Select a file/take a photo
                        </v-btn>
                      </file-upload>
                    </div>
                    <div v-if="files && files.length && !files[0].success && !files[0].active" class="mt-2">
                      <v-btn v-if="!upload || !upload.active" @click.prevent="upload.active=true">
                        Start upload
                      </v-btn>
                      <v-btn v-if="!upload || !upload.active" @click.prevent="files=[]" class="ml-2">
                        Cancel
                      </v-btn>
                      <v-btn type="button" v-else @click.prevent="upload.active = false">
                        Stop Upload
                      </v-btn>
                    </div>
                    <div v-if="files && files.length && files[0].success" class="mt-2">
                      <v-btn type="button" @click.prevent="uploadAnother">
                        Upload another
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="referralType === 'TEXT'">
            <v-col cols="12" class="pb-8">
              <span class="subtitle-1 grey--text text--darken-2">{{
                  $_t('Reason for scan *')
                }}</span>

              <div v-if="settings.referralReasons && Array.isArray(settings.referralReasons)" class="referral-reasons">
                <v-radio-group v-model="referralReason">
                  <v-radio
                      v-for="(reason, index) in settings.referralReasons"
                      :key="index"
                      :label="reason"
                      :value="reason"
                  ></v-radio>
                </v-radio-group>
                <span class="subtitle-1 grey--text text--darken-2">{{
                    $_t('Other')
                  }}</span>
              </div>
              <v-textarea
                  v-model="custom9"
                  outlined
                  name="input-7-4"
                  label=""
                  value=""
                  rows="5"
                  auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
          <div v-if="config.dataStorageConsentEnabled">
            <span :style="{'color' : dataStorageConsentAnswered ? '' : 'red'}">
              * {{ $_t('Do you consent to your data being stored for marketing communications from')}} {{ config.customerName }} ? 
            </span>
            <v-row class="pl-3 mb-2">
              <v-checkbox
                  v-model="selectedConsentOption"
                  :label="$_t('Yes')"
                  value="true"
                  class="mr-2"
              />
              <v-checkbox
                  v-model="selectedConsentOption"
                  :label="$_t('No')"
                  value="false"
              />
            </v-row>
          </div>
          <v-checkbox
              v-model="termsAndConditionsCheckbox"
              :rules="[(v) => !!v || $_t('Please accept terms and conditions.')]"
              class="mt-0"
              required
          >
            <template v-slot:label>
              <div>
                  *
                  {{ $_t('I have read and agree to the')}}
                  <a
                      @click.stop
                      :href="config.urlTermsAndConditions"
                      target="_blank"
                  >{{ $_t('terms and conditions') }}</a
                  >
                  {{$_t('including cancellation policy.') }}

              </div>
            </template>
          </v-checkbox>
          <v-checkbox
              v-model="personalDataCheckbox"
              class="mt-0"
              required
              :rules="[(v) => !!v || $_t('Please acknowledge what happens to Your personal data.')]"
          >
            <template v-slot:label>
              <div>
                *
                {{ $_t('I understand what happens to my personal data.')}}
              </div>
            </template>
          </v-checkbox>
          <div>
           <p> {{ $_t('What happens to your personal data.')}}</p> 
            <div v-if="config.urlPrivacyNotice">
              {{ $_t(`The personal data you have provided will be processed in accordance with ${config.customerName}`)}}
              <a
                  @click.stop
                  :href="config.urlPrivacyNotice"
                  target="_blank"
              >
               {{ $_t('Privacy Notice') }}
              </a>.
            </div>
          </div>
          <p>
            {{$_t(`If you provide your consent, we will send you marketing communications. For the purpose of ${config.customerName} processing of your personal data, the legal basis relied upon for processing your personal data is a contractual arrangement. Records Retention Schedule and ${config.customerName} Privacy Notice provides full details in relation to how long we retain information.`)}}
          </p>
          <p>
            {{$_t('We embed the GDPR data protection principals in the management of personal information. For more information on these principals, how to exercise your rights and freedoms and how to contact us please visit our ')}}
            <a
              :href="config.urlDataProtectionPolicy"
              target="_blank"
            >{{$_t('privacy webpage')}}</a>.
          </p>
          <p>
            {{$_t('Our terms and conditions which include details of our cancellation policy can be found')}}&nbsp;
            <a
              :href="config.urlTermsAndConditions"
              target="_blank"
            >
              {{$_t('here')}}
            </a>.
          </p>
          <v-btn
              class="full-width text-none"
              color="primary"
              @click="submitForm"
              x-large
              :disabled="!valid || submitted"
          >
            <span v-if="!submitted">
              {{ $_t('Book Appointment') }}
            </span>
            <span v-else>
              <v-progress-circular
                  :size="24"
                  color="primary"
                  indeterminate
              ></v-progress-circular>
            </span>
          </v-btn>
        </v-form>
      </v-col>
      <v-col cols="12">
        <v-alert color="red" type="error" v-if="!valid">
          {{ $_t('Not all mandatory fields have been completed') }}
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import config from '@/config';
import moment from 'moment/moment';
import { computed, onMounted, ref, watch } from '@vue/composition-api';
import { bookingStore } from '@/store/booking-store';
import Vue from 'vue';
import FileUpload from 'vue-upload-component'

const VueTelInput = () =>
    Promise.all([
      import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input'),
      import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input/dist/vue-tel-input.css'),
    ]).then(([{ VueTelInput }]) => VueTelInput);

export default {
  name: 'UK',
  components: { VueTelInput, FileUpload },
  setup() {
    const updatePatientUrl = computed(
        () => bookingStore.getState().apiUrls.updatePatientUrl
    );

    const insurer = computed(() => bookingStore.getState().insurer);
    const referral = computed(() => bookingStore.getState().referral);

    /* settings */
    let settings = {}

   
    const personalDataCheckbox = ref(false)
    const dataStorageConsentAnswered = ref(true)

    if (config.patientFormSettingsJson) {
      try {
        settings = JSON.parse(atob(config.patientFormSettingsJson))
      } catch (e) {
        settings = {}
      }
    }

    /* fields */
    const form = ref();
    const submitted = ref(false);
    const valid = ref(true);
    const name = ref('');
    const surname = ref('');
    const patientNumber = ref('');
    const streetName = ref('');
    const streetNumber = ref('');
    const aptNumber = ref('');
    const zipCode = ref('');
    const town = ref('');
    const phoneNumber = ref('');
    const email = ref('');
    const agreementEmailCheckbox = ref(true);
    const agreementSMS_MMSCheckbox = ref(false);
    const agreementPhoneCheckbox = ref(false);
    const termsAndConditionsCheckbox = ref(false);
    const disabledInputs = ref([]);
    const disabledDoB = ref(false);
    const birthday = ref(null);
    const birthdayMenu = ref(false);
    const birthdayPicker = ref();
    const birthdayFormatted = ref();
    const custom9 = ref('')
    const referralType = ref('')
    const referralReason = ref('')
    const sex = ref('')
    const sexList = [{text: 'Female', value: 'F'}, {text: 'Male', value: 'M'}, {text: 'Other', value: 'U'}]
    const selectedConsentOption = ref('')


    /* rules */
    const nameRules = [
      (v) =>
          !!v ||
          Vue.prototype.$_t('First name') +
          ' ' +
          Vue.prototype.$_t('is required'),
      (v) =>
          (v && v.length <= 50) ||
          Vue.prototype.$_t('First name') +
          ' ' +
          Vue.prototype.$_t('must be less than or equal to') +
          ' 50 ' +
          Vue.prototype.$_t('characters')
    ];
    const surnameRules = [
      (v) =>
          !!v ||
          Vue.prototype.$_t('Last name') + ' ' + Vue.prototype.$_t('is required'),
      (v) =>
          (v && v.length <= 50) ||
          Vue.prototype.$_t('Last name') +
          ' ' +
          Vue.prototype.$_t('must be less than or equal to') +
          ' 50 ' +
          Vue.prototype.$_t('characters')
    ];
    const patientNumberRules = [
      (v) =>
          !v ||
          validatePatientNumber(v) ||
          Vue.prototype.$_t('Patient number is invalid')
    ];
    const phoneNumberRules = [
      (v) =>
          !!v ||
          Vue.prototype.$_t('Mobile number is required'),
      (v) =>
          !isNaN(
              v
          ) || Vue.prototype.$_t('Phone number must only contain numbers')
    ];
    const emailRules = [
      (v) =>
          /^$|.+@.+\..+/.test(v) || Vue.prototype.$_t('E-mail must be valid'),
      (v) =>
          (!v && !agreementEmailCheckbox.value) ||
          (v && v.length <= 50) ||
          Vue.prototype.$_t('Email') +
          ' ' +
          Vue.prototype.$_t('must be less than or equal to') +
          ' 50 ' +
          Vue.prototype.$_t('characters')
    ];
    const birthdayRules = [
      (v) =>
          !!v ||
          Vue.prototype.$_t('Birth day') +
          ' ' +
          Vue.prototype.$_t('is required_f'),
      (v) =>
          (v && v.length <= 10) ||
          Vue.prototype.$_t('Birth day') +
          ' ' +
          Vue.prototype.$_t('must be less than or equal to') +
          ' 10 ' +
          Vue.prototype.$_t('characters')
    ];

    const multiplyByPosition = (digit, index) => {
      // multiple each digit by 11  minus its position (indexed from 1)
      return digit * (11 - (index+1));
    }

    const addTogether = (previousValue, currentValue) => {
      return previousValue + currentValue;
    }

    const validatePatientNumber = (nhsNumber) => {
      if(
          nhsNumber === undefined ||
          nhsNumber === null ||
          isNaN(Number(nhsNumber)) ||
          nhsNumber.toString().length !== 10
      ){
        return false;
      }

      if(Number.isInteger(nhsNumber)){
        nhsNumber = nhsNumber.toString();
      }

      const nhsNumberAsArray = nhsNumber.split('');
      const remainder = nhsNumberAsArray.slice(0, 9)
          .map(multiplyByPosition)
          .reduce(addTogether, 0) % 11;

      let checkDigit = 11 - remainder;

      // replace 11 for 0
      if(checkDigit === 11){
        checkDigit = 0;
      }

      const providedCheckDigit = nhsNumberAsArray[9];

      // Do the check digits match?
      return checkDigit === Number(providedCheckDigit);
    };

    const birthdayFromPatientNumber = (patientNumber) => {
      const aInt = [];
      for (let i = 0; i < 11; i++) {
        aInt[i] = parseInt(patientNumber.substring(i, i + 1));
      }

      let y = 1900 + aInt[0] * 10 + aInt[1];
      if (aInt[2] >= 2 && aInt[2] < 8) y += Math.floor(aInt[2] / 2) * 100;
      if (aInt[2] >= 8) y -= 100;

      const m = (aInt[2] % 2) * 10 + aInt[3];
      const d = aInt[4] * 10 + aInt[5];
      return new Date(y, m - 1, d);
    };

    watch(patientNumber, (val) => {
      if (validatePatientNumber(val)) {
        disabledDoB.value = true;
        birthday.value = birthdayFromPatientNumber(val);
        setBirthday();
      } else {
        disabledDoB.value = false;
        birthday.value = null;
      }
    });

    watch(birthdayMenu, (val) => {
      val && setTimeout(() => (birthdayPicker.value.activePicker = 'YEAR'));
    });

    const init = () => {
      if (referral.value && referral.value.patient) {
        const patient = referral.value.patient;
        /* address */
        if (patient.address) {
          if (patient.address.apartmentNumber) {
            aptNumber.value = patient.address.apartmentNumber;
            disabledInputs.value.push('aptNumber');
          }
          if (patient.address.city) {
            town.value = patient.address.city;
            disabledInputs.value.push('town');
          }
          if (patient.address.houseNumber) {
            streetNumber.value = patient.address.houseNumber;
            disabledInputs.value.push('streetNumber');
          }
          if (patient.address.postalCode) {
            zipCode.value = patient.address.postalCode;
            disabledInputs.value.push('zipCode');
          }
          if (patient.address.streetName) {
            streetName.value = patient.address.streetName;
            disabledInputs.value.push('streetName');
          }
        }
        /* etc. */
        if (patient.dateOfBirth) {
          const dob = new Date(patient.dateOfBirth * 1000);
          birthday.value =
              dob.getFullYear() +
              '-' +
              ('0' + dob.getMonth()).slice(-2) +
              '-' +
              dob.getDate();
          setBirthday();
          disabledInputs.value.push('birthday');
        }
        if (patient.firstName) {
          name.value = patient.firstName;
          disabledInputs.value.push('name');
        }
        if (patient.lastName) {
          surname.value = patient.lastName;
          disabledInputs.value.push('surname');
        }
        if (patient.patientNumber) {
          patientNumber.value = patient.patientNumber;
          birthday.value = birthdayFromPatientNumber(patient.patientNumber);
          setBirthday();
          disabledInputs.value.push('birthday');
          disabledInputs.value.push('patientNumber');
        }
      }

      referralType.value = isReferralLetterRequired.value ? 'UPLOAD' : 'TEXT'
    }

    onMounted(init);

    const getFormObj = () => {
      return {
        first_name: name.value || '',
        last_name: surname.value || '',
        address_1: streetName.value ? streetName.value : '',
        address_2: streetNumber.value ? streetNumber.value : '',
        address_3: aptNumber.value ? aptNumber.value : '',
        town: town.value || '',
        post_code: zipCode.value || '',
        email_address_1: email.value ? email.value : '',
        patient_number_1: patientNumber.value || '',
        mobile_number_1: phoneNumber.value || '',
        date_of_birth_sql: moment(birthday.value).format('YYYY-MM-DD') || '',
        sex: sex.value || '',
        custom_9: [custom9.value, referralReason.value].filter(el => !!el).join(' | '),
        dataStorageConsent: selectedConsentOption.value || ''
      };
    };

    const formParams = () => {
      const form = getFormObj();

      let urlParams =
          '?' +
          Object.keys(form)
              .map(function(key) {
                return 'patientForm[' + key + ']=' + encodeURIComponent(form[key]);
              })
              .join('&');

      if (insurer.value) {
        urlParams += '&insurerId=' + insurer.value.insurer_id;
      }

      return urlParams;
    };

    const submitForm = () => {
      if(!selectedConsentOption.value){
        Vue._notify.error( Vue.prototype.$_t('Please provide an answer to data storage agreement.'))
        dataStorageConsentAnswered.value = false
        valid.value = false
        return
      } else {
        dataStorageConsentAnswered.value = true
      }

      if (!phoneNumber.value || !mobileValid) {
        Vue._notify.error('Please provide a valid mobile number.')
        mobileValid.value = false
        return
      }

      const referralText =  [custom9.value, referralReason.value].filter(el => !!el).join(' ')

      if (referralType.value === 'UPLOAD' && (!files.value || !files.value.length || !files.value[0].success)) {
        if (isReferralLetterRequiredInAdvance.value) {
          Vue._notify.error('Please upload your referral.')
          return
        }
      } else if (referralType.value === 'TEXT' && (!referralText || !referralText.trim())) {
        Vue._notify.error('Please provide a reason for the scan.')
        return
      }

      if (form.value.validate()) {
        submitted.value = true;
        fetch(updatePatientUrl.value + formParams(), {
          mode: 'cors',
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        }).then((response) => {
          response.json().then((response) => {
            if (response.status !== 'S') {
              Vue._notify.error('Could not store patient data');
              return;
            }

            bookingStore.setPatientFormData(getFormObj());
            bookingStore.setPatientFormSubmitted(true);
          });
        });
      }
    };

    const getLocale = () => {
      if (
          config.languageSwitchEnabled &&
          localStorage.getItem('lang') &&
          localStorage.getItem('lang') !== ''
      ) {
        return localStorage.getItem('lang');
      }

      return config.defaultLanguage;
    };

    const setBirthday = () => {
      if (moment(birthday.value).isValid()) {
        birthdayFormatted.value = moment(birthday.value).format(
            config.dateFormat
        );
      }
      birthdayMenu.value = false;
    };

    const mobileValid = ref(true)
    const mobileValidation = ref(false)

    const onValidateMobile = ({ valid }) => {
      if (!mobileValidation.value) {
        mobileValidation.value = true
        return
      }

      if (!phoneNumber.value) {
        mobileValid.value = false
      } else {
        mobileValid.value = valid
      }
    }

    const onBlurMobile = () => {
      // mobileValid.value = mobileValidTemp.value
    }
    const upload = ref(null)

    const files = ref([])
    function inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }

        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    }

    const uploadRoute = computed(() => {
      return bookingStore.getState().apiUrls.uploadReferralUrl
    })

    const uploadAnother = () => {
      files.value = []
    }

    const isReferralLetterRequired = computed(() => {
      return bookingStore.getProcedure().require_referral_letter === '1' || bookingStore.getProcedure().require_referral_letter_in_advance === '1'
    })

    const isReferralLetterRequiredInAdvance = computed(() => {
      return bookingStore.getProcedure().require_referral_letter_in_advance === '1'
    })

    return {
      config,
      form,
      valid,
      name,
      surname,
      patientNumber,
      streetName,
      streetNumber,
      aptNumber,
      zipCode,
      town,
      phoneNumber,
      email,
      agreementEmailCheckbox,
      agreementSMS_MMSCheckbox,
      agreementPhoneCheckbox,
      termsAndConditionsCheckbox,
      nameRules,
      phoneNumberRules,
      patientNumberRules,
      emailRules,
      submitForm,
      submitted,
      disabledInputs,
      disabledDoB,
      birthday,
      birthdayFormatted,
      birthdayMenu,
      birthdayPicker,
      getLocale,
      birthdayRules,
      setBirthday,
      surnameRules,
      custom9,
      onBlurMobile,
      onValidateMobile,
      mobileValid,
      referralType,
      files,
      inputFilter,
      upload,
      uploadRoute,
      uploadAnother,
      settings,
      sex,
      sexList,
      referralReason,
      isReferralLetterRequired,
      isReferralLetterRequiredInAdvance,
      selectedConsentOption,
      personalDataCheckbox,
      dataStorageConsentAnswered
    };
  }
};
</script>
<style scoped>
  .referral-reasons .v-radio {
    align-items: start
  }
</style>